.circle{
    z-index: 1;
    border-radius: 50%;
    width: 600px;
    height: 600px;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

#home-circle{
    background-image: url('../../assets/images/daisychain.jpeg');
    background-size: contain;
}
  
#title{
    width: 450px;
    height: 450px;
    font-size: 120px;
    font-weight: 700;
    text-wrap: wrap;
    word-break: break-all;
}

#project-circle{
    background-image: url('../../assets/images/daisychain.jpeg');
    box-shadow: inset 0 0 0 1000px rgba(54, 111, 117, 0.9);
    color: var(--accent-four);
}

#about-circle{
    background-image: url('../../assets/images/daisychain.jpeg');
    box-shadow: inset 0 0 0 1000px rgba(228, 157, 167, 0.9);
    color: black
}

#contact-circle{
    background-image: url('../../assets/images/daisychain.jpeg');
    box-shadow: inset 0 0 0 1000px rgba(54, 111, 117, 0.9);
    color: var(--accent-four);
}

#projects-list{
    list-style-type: none;
    line-height: 2;
    font-size: 18px;
    text-align: center;
    padding: 0px;
    color: var(--accent-four);
}

.proj-list-link{
    color: var(--accent-four);
}

.proj-list-link:hover{
    color: var(--accent-three);
    cursor: pointer;
}

#about-text{
    font-size: 18px;
    margin-left: 15px;
    margin-right: 15px;
}
  
#contact-text{
    font-size: 18px;
    margin-left: 15px;
    margin-right: 15px;
}

@media only screen and (max-width: 1100px){
    .circle{
        width: 500px;
        height: 500px;
    }

    #title{
        width: 400px;
        height: 400px;
        font-size: 105px;
    }
}

@media only screen and (max-width: 900px){
    .circle{
        width: 400px;
        height: 400px;
    }

    #title{
        width: 300px;
        height: 300px;
        font-size: 80px;
    }
}

@media only screen and (max-width: 750px) {
  .circle{
    width: 300px;
    height: 300px;
  }

  #title{
    width: 200px;
    height: 200px;
    font-size: 50px;
  }

  .circle h1{
    font-size: 20px;
}

  #about-text{
    font-size: 16px;
    }

    #contact-text{
        font-size: 16px;
    }

    #projects-list{
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .circle{
        width: 250px;
        height: 250px;
    }

    .circle h1{
        font-size: 16px;
    }

    #title{
        width: 200px;
        height: 200px;
        font-size: 50px;
    }

    #about-text{
        font-size: 14px;
    }

    #contact-text{
        font-size: 14px;
    }

    #projects-list{
        font-size: 14px;
    }
}