:root {
  --accent-one: #7AAD3C;
  --accent-two: #366F75;
  --accent-three: #E49DA7;
  --accent-four: #F3F2D4;
}

/* width */
::-webkit-scrollbar{
  width: 1em;
}

/* track */
::-webkit-scrollbar-track{
  background: var(black);
}

/* handle */
::-webkit-scrollbar-thumb{
  background: var(--accent-three);
  border-radius: 20px;
  border: 3px solid var(--accent-three);
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: #C19298;
  border: 3px solid #C19298;
  cursor: pointer;
}

body {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  max-width: 100vw;
  background-color: black;

  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0px;
}

footer{
  z-index: 0;
  margin-top: auto;
  margin-bottom: 25px;
  padding: 0px 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) { 
  footer{
    margin-top: 160px;
  }
}

@media only screen and (max-width: 900px) { 
  footer{
    margin-top: 160px;
  }
}

@media only screen and (max-width: 750px) { 
  footer{
    margin-top: 200px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 400px) { 
  footer{
    margin-top: 160px;
  }
}