.main{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content{
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-buttons{
  z-index: 0;
  position: absolute;
  display: flex;
  margin-top: 40px;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
  height: 720px;
  width: 745px;
  border-radius: 50%;
}

.nav-button{
  margin-top: 25px;
  margin-bottom: 25px;
  width: fit-content;
  text-align: center;
  transform: scale(1.0);
  transition: 0.1s ease-in;
}

.nav-button:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.1s ease-out;
}

.nav-icon{
  width: 70px;
  height: 70px;
  margin: 0px;
}

.nav-text{
  margin: 0px;
  font-size: 18px;
  color: var(--accent-three);
}

#home-icon{
  margin-left: 54px;
}

#proj-icon{
  margin-left: 0px;
}

#about-icon{
  margin-left: 7px;
}

#contact-icon{
  margin-left: 75px;
  margin-top: 20px;
}

.footer-text{
  text-align: center;
  color: var(--accent-three);
}

.footer-text a{
  color: var(--accent-one);
}

.page-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content{
  color: var(--accent-three);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 1100px) {
  .nav-buttons{
    width: 645px;
    height: 625px;
  }
}

@media only screen and (max-width: 900px){
  .nav-buttons{
    width: 550px;
    height: 400px;
  }

  .nav-button{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .nav-icon{
    width: 60px;
    height: 60px;
    margin: 0px;
  }
}

@media only screen and (max-width: 750px) {
  .nav-buttons{
    width: 450px;
    height: 300px;
  }

  .nav-button{
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .nav-icon{
    width: 50px;
    height: 50px;
    margin: 0px;
  }

  .nav-text{
    font-size: 16px;
  }

  #home-icon{
    margin-left: 40px;
  }

  #proj-icon{
    margin-left: 5px;
  }
  
  #about-icon{
    margin-left: 20px;
  }
  
  #contact-icon{
    margin-left: 50px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .nav-buttons{
    max-width: 95vw;
    max-height: 95vh;
  }

  .nav-button{
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .nav-icon{
    width: 35px;
    height: 35px;
    margin: 0px;
  }

  .nav-text{
    font-size: 14px;
  }

  #home-icon{
    margin-left: 35px;
  }
  
  #proj-icon{
    margin-left: 5px;
  }
  
  #about-icon{
    margin-left: 20px;
  }
  
  #contact-icon{
    margin-left: 45px;
    margin-top: 0px;
  }
}

/*
#proj-icon{
  top: 350px;
  right: 700px;
}

#about-icon{
  top: 500px;
  right: 680px;
}

#contact-icon{
  top: 625px;
  right: 595px;
}
*/