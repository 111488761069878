.proj-nav-link{
    text-decoration: none;
    color: var(--accent-three);
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: scale(1.0);
    transition: 0.2s ease-in;
}

.proj-nav-link:hover{
    cursor: pointer;
    transform: scale(1.02);
    transition: 0.2s ease-out;
}

.proj-nav-circle{
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-image: url('../../assets/images/daisychain.jpeg');
    background-size: contain;
    padding: 0px;
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#proj-nav-title{
    width: 100px;
    height: 100px;
    font-size: 24px;
    font-weight: 700;
    text-wrap: wrap;
    word-break: break-all;
}

.proj-footer{
    text-align: center;
    margin-top: auto;
    font-size: 16px;
}

.proj-footer-list{
    color: var(--accent-three);
}

.proj-footer-list li{
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
}

.proj-footer-list a{
    color: var(--accent-one);
}

.proj-footer-list a:hover{
    color: var(--accent-three);
}

.projectPageTitle{
    font-size: 24px;
    margin-bottom: 0px;
}

.projectPageSubTitle{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 30px;
}

.projectGallery{
    height: fit-content;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: baseline;
}

.projectTile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: fitContent;
    width: 233px;
    margin: 10px;
  }

  .projectImage{
    height: 250px;
  }

  .projectTitle{
    margin: 0px;
    font-size: 22px;
  }
  
  .projectDate{
    margin: 0px;
    font-size: 18px;
    font-weight: normal;
  }